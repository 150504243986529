import React from "react"
import { css } from "@emotion/core"

import { rhythm } from "../../utils/typography"

export default ({ children, styles, visible }) => {
  styles = [
    css`
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: var(--base3);
      box-shadow: 0 0 5px #fcfcfc;
      display: ${visible ? "flex" : "none"};
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .contents {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0 ${rhythm(1)};
        max-width: 40rem;
        width: 40rem;
        .up {
          padding-left: 0.5rem;
        }
      }
      @media screen and (max-width: 40rem) {
        width: 100%;
      }
    `,
    styles,
  ]

  return (
    <section className="bar" css={styles}>
      <section className="contents">
        {children}
        <a className="up" href="..">↑</a>
      </section>
    </section>
  )
}
