// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-design-colors-js": () => import("./../src/pages/design/colors.js" /* webpackChunkName: "component---src-pages-design-colors-js" */),
  "component---src-pages-design-parts-js": () => import("./../src/pages/design/parts.js" /* webpackChunkName: "component---src-pages-design-parts-js" */),
  "component---src-pages-drafts-index-js": () => import("./../src/pages/drafts/index.js" /* webpackChunkName: "component---src-pages-drafts-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-audio-js": () => import("./../src/templates/audio.js" /* webpackChunkName: "component---src-templates-audio-js" */),
  "component---src-templates-default-js": () => import("./../src/templates/default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-youtube-js": () => import("./../src/templates/youtube.js" /* webpackChunkName: "component---src-templates-youtube-js" */)
}

