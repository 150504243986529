import React, { useEffect, useRef, useState } from "react"
import { Global, css } from "@emotion/core"
import ReactPlayer from "react-player/youtube"

import Bar from "../parts/bar"
import Footer from "../parts/footer"
import globalStyles from "../../utils/global-styles"
import { rhythm } from "../../utils/typography"
import SEO from "../seo"

const styles = css`
  height: 100%;
  .movie {
    width: 100%;
    aspect-ratio: 16 / 8.7;
  }
  .contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    margin: 0 auto;
    max-width: 40rem;
  }
  main {
    margin-top: 4rem;
    padding: 0 ${rhythm(1)};
    flex: 0 0 auto;
  }
`

export default ({ children, title, videoId }) => {
  const [playerVisible, setPlayerVisible] = useState(true)

  const togglePlayerVisible = e => {
    if (playerVisible) {
      setPlayerVisible(false)
    } else {
      setPlayerVisible(true)
    }
  }

  return (
    <div css={styles} onClick={togglePlayerVisible}>
      <Global styles={globalStyles} />
      <SEO title={title} />
      <div class="movie">

        <ReactPlayer
          url={`https://www.youtube.com/watch?v=${videoId}`}
          width={"100%"}
          height={"100%"}
          playing={false} // 自動再生
          loop={false} // ループ再生
          controls={false} // 動画の操作が可能かどうか
          config={{
            youtube: {
              playerVars: { modestbranding: 1, controls: 1 }
            }
          }}
        />
      </div>
      <div class="contents">
        <main>
          {children}
        </main>
        <Footer></Footer>
      </div>
    </div>
  )
}
