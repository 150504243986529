import React from "react"
import { css } from "@emotion/core"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import {
  faAmazon,
  faApple,
  faSoundcloud,
  faSpotify,
} from "@fortawesome/free-brands-svg-icons"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout/default"

export default () => {
  const { site } = useStaticQuery(query)
  return (
    <Layout title={site.siteMetadata.title}>
      <div
        css={css`
          ul {
            margin-left: 0;
          }
          li {
            list-style-type: none;
          }
        `}
      >
        <h1>{site.siteMetadata.title}</h1>

        <h2>歌</h2>
        <ul>
          <li>
            <Link to="/shushi">種子</Link>
          </li>
          <li>
            <Link to="/stungun">欠けた月とスタンガン</Link>
          </li>
          <li>
            <Link to="/harumushi">春虫</Link>
          </li>
          <li>
            <Link to="/river-and-flower">川と花</Link>
          </li>
          <li>
            <Link to="/thank-you">サンキュー</Link>
          </li>
          <li>
            <Link to="/sergio-mendes">セル塩麺DEATH</Link>
          </li>
        </ul>

        <hr class="divider2"></hr>

        <h2>インストルメント</h2>
        <ul>
          <li>
            <Link to="/a-seed">A Seed</Link>
          </li>
          <li>
            <Link to="/space-gas-stove">Space Gas Stove</Link>
          </li>
          <li>
            <Link to="/i-am-sad-now">I Am Sad Now</Link>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        twitterUsername
      }
    }
  }
`
